import React from 'react'
import Seo from '../components/Seo/Seo'
import { Link } from 'gatsby'
import Emoji from '../images/emoji-404.png'

const NotFound = ({ location }) => (
  <>
    <Seo
      title="عفوا! الصفحة غير موجودة"
      description="الصفحة التي تبحث عنها لم تعد موجودة أو نُقلت."
      path={location.pathname}
    />

    <div className="container px-3 py-16 mx-auto max-w-3xl">
      <h1>عفوًا! تعذّر العثور على الصفحة… </h1>
      <p> الصفحة التي تبحث عنها لم تعد موجودة أو نُقلت! </p>
      <p>
        قد يكون ما تبحث عنه في <Link to="/blog">قائمة التدوينات</Link>.
        <img
          src={Emoji}
          alt="Emoji about searching"
          width="160"
          height="160"
          className="mx-auto"
        ></img>
      </p>
    </div>
  </>
)

export default NotFound
